import React, { useState } from 'react';
import bottomline from "../newComponents/asset/bottomline.png";
import { Helmet } from "react-helmet";


const Nearby = () => {
  const [activeFilter, setActiveFilter] = useState('all');

  const categories = {
    nature: { label: 'Nature' },
    adventure: { label: 'Adventure' },
    spiritual: { label: 'Spiritual' },
    citylife: { label: 'City Life' },
  };

  const destinations = [
    {
      name: "Forest Trekking",
      distance: 0,
      image: "https://d2ehq5aws28ia0.cloudfront.net/nearby/nr1.webp",
      category: "nature",
      latitude: 29.3774,
      longitude: 79.8084,
      alt:"Forest Trekking in Mukteshwar"
    },
    {
      name: "Bhalu Gaad Waterfall",
      distance: 12,
      image: "https://d2ehq5aws28ia0.cloudfront.net/nearby/nr2.webp",
      category: "nature",
      latitude: 29.3432,
      longitude: 79.8043,
      alt:"Bhalu Gaad Waterfall"
    },
    {
      name: "Mahadev Temple",
      distance: 15,
      image: "https://d2ehq5aws28ia0.cloudfront.net/nearby/nr3maha.png",
      category: "spiritual",
      latitude: 29.4062,
      longitude: 79.7726,
      alt:"Mahadev Temple Mukteshwar"
    },
    {
      name: "Satkhol Ashram",
      distance: 26,
      image: "https://d2ehq5aws28ia0.cloudfront.net/nearby/nr4.webp",
      category: "spiritual",
      latitude: 29.4693,
      longitude: 79.7365,
      alt:"Satkhol Ashram"
    },
    {
      name: "Bhimtal",
      distance: 31,
      image: "https://d2ehq5aws28ia0.cloudfront.net/nearby/nr5.webp",
      category: "citylife",
      latitude: 29.2206,
      longitude: 79.6015,
      alt:"Bhimtal Lake"
    },
    {
      name: "Paragliding Adventure",
      distance: 35,
      image: "https://d2ehq5aws28ia0.cloudfront.net/nearby/nr6.webp",
      category: "adventure",
      latitude: 29.3753,
      longitude: 79.6072,
      alt:"Paragliding in Nainital"
    },
    {
      name: "Mall Road Nainital",
      distance: 46,
      image: "https://d2ehq5aws28ia0.cloudfront.net/nearby/nr7.webp",
      category: "citylife",
      latitude: 29.3800,
      longitude: 79.4516,
      alt:"Mall Road Nainital"
    },
    {
      name: "Almora",
      distance: 59,
      image: "https://d2ehq5aws28ia0.cloudfront.net/nearby/nr8.webp",
      category: "citylife",
      latitude: 29.5982,
      longitude: 79.6816,
      alt:"Almora"
    },
    {
      name: "Jageshwar Dham",
      distance: 65,
      image: "https://d2ehq5aws28ia0.cloudfront.net/nearby/nr9.webp",
      category: "spiritual",
      latitude: 29.5457,
      longitude: 79.7432,
      alt:"Jageshwar Dham"
    },
    {
      name: "Ranikhet",
      distance: 80,
      image: "https://d2ehq5aws28ia0.cloudfront.net/nearby/nr10.webp",
      category: "nature",
      latitude: 29.6200,
      longitude: 79.4600,
      alt:"Ranikhet"
    },
    {
      name: "Binsar",
      distance: 80,
      image: "https://d2ehq5aws28ia0.cloudfront.net/nearby/nr11.webp",
      category: "nature",
      latitude: 29.6153,
      longitude: 79.5545,
      alt:"Binsar"
    },
    {
      name: "Kausani",
      distance: 108,
      image: "https://d2ehq5aws28ia0.cloudfront.net/nearby/nr12.webp",
      category: "nature",
      latitude: 29.7245,
      longitude: 79.6548,
      alt:"Kausani"
    },
  ];

  const filteredDestinations = activeFilter === 'all'
    ? destinations
    : destinations.filter(dest => dest.category === activeFilter);

  return (
    <>
    <div className="relative h-[200px] w-full">
      {/* Helmet for SEO */}
      <Helmet>
        <title>Things to Do Near Mukteshwar | Discover Nearby Scenic Spots</title>
        <meta
          name="description"
          content="Explore nearby spots around Indus Valley. From scenic views to natural wonders, discover the best of Uttarakhand while considering our luxury property for sale."
        />
      </Helmet>

        <div className="absolute inset-0 bg-opacity-50 flex items-center justify-center">
          <h1 className="text-white text-4xl md:text-5xl font-bold">    
          </h1>
        </div>
      </div>    
    <div className="min-h-screen py-12 px-4">
      <div className="max-w-screen-xl mx-auto">
        {/* Header Section */}
        <div className="text-center mb-10">
        <div className="flex flex-col justify-center items-center">
        <p className="text-4xl font-bold text-center poppins text-green-700 uppercase tracking-wider yeseva">
        Discover Nearby Wonders
        </p>
        <img className="ml-[-10px]" src={bottomline} alt="" />
      </div>
          <p className="text-lg text-gray-600">Explore breathtaking destinations around you</p>
        </div>

        {/* Category Filters */}
        <div className="flex flex-wrap justify-center gap-4 mb-8">
          <button
            onClick={() => setActiveFilter('all')}
            className={`px-6 py-2 rounded-lg border-2 border-green-600 ${activeFilter === 'all' ? 'bg-green-600 text-white' : 'bg-transparent text-green-600 border-green-600'} hover:bg-green-600 hover:text-white transition duration-300`}
          >
            All
          </button>
          {Object.entries(categories).map(([key, { label }]) => (
            <button
              key={key}
              onClick={() => setActiveFilter(key)}
              className={`px-6 py-2 rounded-lg border-2 border-green-600 ${activeFilter === key ? 'bg-green-600 text-white' : 'bg-transparent text-green-600 border-green-600'} hover:bg-green-600 hover:text-white transition duration-300`}
            >
              {label}
            </button>
          ))}
        </div>

        {/* Destinations Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
          {filteredDestinations.map((destination, index) => (
            <a
              key={index}
              href={`https://www.google.com/maps?q=${destination.name}`}
              target="_blank"
              rel="noopener noreferrer"
              className="relative group rounded-lg overflow-hidden shadow-lg bg-white"
            >
              {/* Image Container */}
              <div className="relative h-56">
                <img
                  src={destination.image}
                  alt={destination.name}
                  className="w-full h-full object-cover group-hover:scale-110 transition-transform duration-300"
                />
                <div className="absolute top-4 right-4 bg-green-600 text-white text-xs px-3 py-1 rounded-full">
                  {destination.distance} KM
                </div>
              </div>

              {/* Content */}
              <div className="p-4">
                <h3 className="text-xl font-semibold text-green-800">{destination.name}</h3>
                <p className="text-gray-600 mt-2">
                  {destination.distance === 0 ? 'On location' : `${destination.distance} kilometers away`}
                </p>
              </div>
            </a>
          ))}
        </div>
      </div>
    </div>
    </>
  );
};

export default Nearby;
