import React from 'react'
import Enquiryform from '../newComponents/Enquiryform'

function Contact() {
  return (
    <div>
        
        <div className="relative h-[200px] w-full">

      </div>

        <Enquiryform/>
    </div>
  )
}

export default Contact