import React, { useState, useEffect } from 'react';
import { Calendar, Clock, ArrowUpRight } from 'lucide-react';

const BlogCard = ({ blog }) => {
  const [loading , setLoading] = useState(true)

  useEffect(()=>{
    setTimeout(()=>{
      setLoading(false)
    },200)
  },[])

  return (
    <>
    {loading ?
    <>
    <div className="relative w-[300px] max-w-sm overflow-hidden bg-white shadow-lg transition-all duration-300 animate-pulse">
      {/* Category Tag Skeleton */}
      <div className="absolute left-4 top-4 z-10 h-6 w-20 rounded-full bg-gray-300" />

      {/* Image Skeleton */}
      <div className="relative h-48 bg-gray-300" />

      {/* Content Skeleton */}
      <div className="p-5">
        {/* Title Skeleton */}
        <div className="mb-3 h-6 w-3/4 rounded bg-gray-300" />

        {/* Description Skeleton */}
        <div className="mb-2 h-4 w-full rounded bg-gray-300" />
        <div className="h-4 w-5/6 rounded bg-gray-300" />

        {/* Metadata Skeleton */}
        <div className="mt-4 flex items-center gap-4">
          <div className="flex items-center gap-1">
            <div className="h-5 w-5 rounded bg-gray-300" />
            <div className="h-4 w-24 rounded bg-gray-300" />
          </div>
        </div>
      </div>
    </div>
    </>
    :
    <a
          href={blog.href}
          >
    <div className=" cursor-pointer group relative w-full max-w-sm overflow-hidden bg-white shadow-lg transition-all duration-300 hover:shadow-xl">
      {/* Category Tag */}
      {blog.category && (
        <div className="absolute left-4 top-4 z-10 rounded-full bg-black/70 px-3 py-1 text-xs font-medium text-white backdrop-blur-sm">
          {blog.category}
        </div>
      )}
      
      {/* Image Container */}
      <div className="relative h-48 overflow-hidden">
        <img
          src={blog.image}
          alt={blog.title}
          className="h-full w-full object-cover object-top transition-transform duration-700 group-hover:scale-110"
          loading="lazy"
        />
        <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent opacity-0 transition-opacity duration-300 group-hover:opacity-100" />
      </div>

      {/* Content Container */}
      <div className="relative p-5">
        {/* Title */}
        <h2 className="mb-3 text-xl font-bold tracking-tight text-gray-900 group-hover:text-green-600">
          {blog.title}
        </h2>

        {/* Description */}
        <p className="mb-4 line-clamp-2 text-sm text-gray-600">
          {blog.description}
        </p>

        {/* Metadata */}
        <div className="flex items-center gap-4 text-bse text-gray-500">
          <div className="flex items-center gap-1">
            <Calendar size={20} />
            <span>{blog.date}</span>
          </div>
        </div>

        {/* Read More Link */}
        <a
          href={blog.href}
          target="_blank"
          rel="noopener noreferrer"
          className="mt-4 inline-flex items-center gap-1 text-sm font-medium text-green-600 opacity-0 transition-opacity duration-300 group-hover:opacity-100"
        >
          Read More
          <ArrowUpRight size={16} className="transition-transform duration-300 group-hover:translate-x-1" />
        </a>
      </div>
    </div>
    </a>
    }
    </>
  );
};

export default BlogCard;