import React from 'react'
import route from "./asset/route-map.png"
import bottomline from "../newComponents/asset/bottomline.png"


function RouteMap() {
  return (
    <>
    <div className='mt-5'>
      <div className='flex flex-col mt-12 justify-center items-center'>
        <p className='text-3xl lg:text-4xl font-bold text-center poppins text-green-700 uppercase tracking-wider yeseva'>
          Route Map
        </p>
        <img className='ml-[-10px]' src={bottomline} alt='' />
      </div>
    <div className='w-full'>
        <img className='w-full' src={route} alt="delhi to mukteshwar route map" />
    </div>
    </div>
    </>
  )
}

export default RouteMap