import React, {useEffect} from 'react';
import { Mountain, TreePine, Shield, Plane } from 'lucide-react';
import bottomline from './asset/bottomline.png';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import NotFound from '../screens/NotFound';

const FeatureCard = ({ Icon, title, text }) => (
    <div className="flex flex-col items-center rounded-xl p-6  transition-transform duration-300 hover:-translate-y-1">
      <Icon className="mb-3 h-8 w-8 text-red-800" />
      <h4 className="mb-2 text-lg font-semibold text-gray-800">{title}</h4>
      <p className="text-center text-gray-600">{text}</p>
    </div>
  );

const BlogRenderer = () => {

  const {title} = useParams()
  const navigate = useNavigate()




    // const post = {
    //     title: "Blog Post Title",
    //     category: "Category Name",
    //     author: "Author Name",
    //     authorImage: "author-image-url",
    //     date: "January 1, 2024",
    //     readTime: 5,
    //     featuredImage: "featured-image-url",
    //     content: "HTML content string",
    //     tableOfContents: [
    //       { id: "section-1", title: "Section 1" },
    //       { id: "section-2", title: "Section 2" }
    //     ],
    //     tags: ["tag1", "tag2", "tag3"],
    //     allowSharing: true
    //   }


      const datamain = {
        "best-hill-station-for-real-estate-investment":{
            hero: {
                image: "https://d57jfigkzby0a.cloudfront.net/ivm-blog.png",
                title: "Mukteshwar: Best Hill Station for Real Estate Investment",
                alt: "Best Hill Station for Real Estate Investment"
              },
              introduction: [
                "Mukteshwar has become a hotspot for real estate investment in recent years, especially in the post-COVID era. This hill station has witnessed significant land appreciation, attracting investors from across the country for several key reasons.",
                "One of the major factors contributing to this trend is that 70% of the land in Mukteshwar is reserved for forests, leaving only 30% available for developers. This limited availability has led to a cautious and sustainable approach to urbanization. Unlike other hill stations such as Nainital, Mussoorie, Shimla, Dehradun, and Rishikesh—which have become over-urbanized—Mukteshwar has managed to maintain its natural beauty.",
                "For people living in metropolitan cities like Delhi, Mumbai, and Bengaluru, battling pollution, overcrowding, and extreme weather, Mukteshwar offers a much-needed escape. Over the last 4-5 years, many discerning investors have purchased properties in hill stations for vacation homes, retirement living, or to generate income through homestays. Mukteshwar stands out as an ideal location for such investments due to its serene environment, pleasant climate, and peaceful surroundings."
              ],
              keyFeatures: [
                {
                  icon: Mountain,
                  title: "2,285m Altitude",
                  text: "Enjoy cool, pleasant weather year-round with magical winter snowfall"
                },
                {
                  icon: TreePine,
                  title: "70% Green Cover",
                  text: "Protected forest area ensuring lasting natural beauty"
                },
                {
                  icon: Shield,
                  title: "Peace & Security",
                  text: "Well-maintained law and order with minimal commercialization"
                },
                {
                  icon: Plane,
                  title: "Accessibility",
                  text: "Well-connected via road, rail, and air through Pantnagar airport"
                }
              ],
              highlights: {
                title: "Why Mukteshwar Stands Out",
                points: [
                  "Altitude from sea level: Situated at an altitude of 2,285 meters, Mukteshwar offers cool, pleasant weather all year round, with winter snowfall adding to its charm.",
                  "70% of the area is reserved for greenery: With most of the land protected, Mukteshwar remains lush and green, preserving its natural beauty.",
                  "Peace-centric, not commercialized: Unlike other tourist hotspots, Mukteshwar has retained its spiritual and peaceful vibe, free from excessive commercialization.",
                  "Less crowded: Fewer tourists and limited urbanization ensure that Mukteshwar maintains its tranquility, providing a true escape from city life.",
                  "Large, independent plots: Investors can purchase spacious plots, perfect for building beautiful villas with expansive views.",
                  "Separated commercial areas: The commercial spaces are thoughtfully distanced from residential areas, ensuring peace for homeowners.",
                  "Snowfall: Mukteshwar experiences magical snowfall in winter, adding to its appeal as a picturesque hill station.",
                  "Panoramic Himalayan views: The location offers stunning, uninterrupted views of the majestic Nanda Devi and other Himalayan peaks.",
                  "Well-connected state highways: Mukteshwar is accessible via well-maintained state highways, making the journey comfortable and scenic.",
                  "Better law and order: Mukteshwar is known for its peaceful environment and efficient law enforcement, making it a safe place to invest and live."
                ]
              },
              addon: {
                title: "Mukteshwar's Connectivity",
                paragraphs: [
                  "Mukteshwar is easily accessible by road, rail, and air. The nearest airport is in Pantnagar, and the town is well-connected to major cities, especially Delhi-NCR, through a network of well-constructed state highways.",
                  "The town also offers essential infrastructure such as medical facilities and educational institutions, ensuring that it is a retreat and a well-planned location for long-term living.",
                  "With its natural beauty, limited development, and thoughtful urban planning, Mukteshwar presents a unique opportunity for investors looking for vacation homes, retirement properties, or a peaceful escape from city life."
                ]
              },
              helmet : {
                title:"Mukteshwar: Best Hill Station for Real Estate Investment",
                description:"Are you looking for the best hill station to invest in real estate? Learn why Mukteshwar stands out with its unique opportunities and scenic appeal in this blog"
              }
        },

        "real-estate-investment-options":{
            hero: {
                image: "https://d2ehq5aws28ia0.cloudfront.net/blog2.jpg",
                title: "Real Estate Investment Options and ROI in the Hill Stations",     
                alt:"Real Estate Investment Options in the Hill Stations"
              },
              introduction: [
                "India is a land beautified by the hill stations spread across the country. Investing in real estate at the hills comes with icing on the cake. It is truly a captivating and lucrative opportunity. In recent years, the real estate demand at the hill stations has skyrocketed. The attention to the serene and scenic nature has attracted the attention of investors and homeowners alike. A desire for proximity to nature and a peaceful, tranquil drive drives this shift in demand. It has significantly opened various avenues for Property Investment in Uttarakhand and other hilly regions.",
                "Real estate investment in Hill Stations like Mukteshwar, Shimla, Mussoorie, Ranikhet, etc. are the top locations that attract the attention of the investors. Apart from just offering a peaceful retreat, it only provides an escape from the hustle and bustle of city life. It promises a long-term appreciation and a massive return on investment. There are several key factors involved that make these hilly regions an attractive proposition for both personal use and capital growth. Let’s now deep dive into understanding the Best Hill Stations for Property Investment and the lucrative benefits of investing in such locations.",
              ],
              highlights: {
                title: "Why Invest in Hill Stations?",
                points: [
                  "Growing Demand for Second Homes: As urban areas are getting flooded with the population, more people are interested in having a getaway home. This serves as an asset to not only affluent city dwellers but also those who are looking for a long-term property appreciation in a quiet, serene environment.",
                  "Appreciation in Property Value: It is a widely known fact that it is not easy to acquire a property on the hills, due to their land structure. It requires an expert constructor that uses precise techniques to build homes on hills. Therefore, the demand for the properties on the hill stations is ever growing. Over the last few decades, these areas have become increasingly valuable, making them prime for capital growth.",
                  "Lucrative Rental Income: The trend of acquiring properties in the picturesque locations of the Hill stations has also resulted in lucrative rental incomes for the owners. These properties offer excellent renting potential, particularly during holidays and weekends. The most seamless way to enjoy steady cash flow is by leasing properties as vacation rentals or homestays. Investors and homeowners usually rent out their properties on hills during peak tourist seasons, so as to generate consistent returns.",
                ],
              },
              addon: {
                title: "Property Investment in Uttarakhand: The Time is Now",
                paragraphs: [
                  "Uttarakhand has become one of the top hill stations for real estate investment, all because of its majestic and pristine landscapes. The state’s rapidly improving infrastructure, and government incentives to preserve the lush green environment has made its hills a hotspot for real estate investments. Whether it’s Mukteshwar, Nainital, or Ranikhet, properties in this state promise a significant growth in terms of investments.",
                  "Compared to other established hill stations in the previous decades, Uttarakhand has proven to be relatively affordable. Its proximity to nature remains unmatched with hill stations like Shimla, Manali, etc., making it a prime investment destination.",
                ],
              },
              helmet : {
                title:"Real Estate Investment Options And ROI In The Hill Stations",
                description:"Uttarakhand's stunning and unspoiled landscapes have made it one of the best hill stations for real estate investment. The state is a popular destination for real estate investment"
              }
        }
        
      };

      // useEffect(() => {
      //   if (!data[title]) {
      //     navigate("/404", { replace: true });
      //   }
      // }, [data[title], navigate]);
      
      // if (!data[title]) return null;
      

      
      const data = datamain[title];


  return (
    data !== undefined ?  
    <>
    <article className="min-h-screen">
      {/* Hero Section */}
      <div className="relative">
        <div className="absolute inset-0 bg-black/40" />
        <img
          className="h-[50vh] w-full object-cover object-top"
          src={data.hero.image}
          alt={data.hero.alt}
        />
        <div className="absolute inset-0 flex items-center justify-center">

        </div>
      </div>

      <div className='mx-3 flex flex-col mt-[60px] justify-center items-center'>
                <h1 className='text-3xl font-bold mb-2 text-center poppins text-green-700 uppercase tracking-wider yeseva'>
                    {data.hero.title}
                </h1>
                <img className='ml-[-10px]' src={bottomline} alt='' />
            </div>

        <div className=' my-4 conatiner overflow-hidden w-[80%] mx-auto'>
            <img className='object-cover hover:scale-105 transition-all duration-500' src={data.hero.image}
          alt={data.hero.alt} />
        </div>

      {/* Main Content */}
      <div className="mx-auto max-w-6xl px-4 text-justify">
        {/* Introduction */}
        <div className="rounded-2xl p-6 backdrop-blur-sm">
          {data.introduction.map((paragraph, index) => (
            <p
              key={index}
              className="mb-6 last:mb-0 text-lg leading-relaxed text-gray-800 md:text-xl"
            >
              {paragraph}
            </p>
          ))}
        </div>

        
      <Helmet>
        <title>{data.helmet.title}</title>
        <meta
          name="description"
          content={data.helmet.description}
        />
      </Helmet>

        {/* Key Features Grid */}
        {data.keyFeatures &&
        <div className="mb-12 grid gap-6 md:grid-cols-2 lg:grid-cols-4">
          { data.keyFeatures.map((feature, index) => (
            <FeatureCard
              key={index}
              Icon={feature.icon}
              title={feature.title}
              text={feature.text}
            />
          ))}
        </div>
}

        {/* Highlights Section */}
        <section className="">
          <h2 className="mb-8 text-center text-3xl font-bold text-red-800 md:text-4xl">
            {data.highlights.title}
          </h2>
          <div className="rounded-2xl p-6 backdrop-blur-sm">
            <ol className="ml-6 list-decimal space-y-4 text-lg text-gray-800">
              {data.highlights.points.map((point, index) => (
                <li key={index} className="leading-relaxed">{point}</li>
              ))}
            </ol>
          </div>
        </section>

        {/* Connectivity Section */}
        <section className="rounded-2xl p-6 backdrop-blur-sm">
          <h2 className="mb-6 text-center text-3xl font-bold text-red-800">
            {data.addon.title}
          </h2>
          <div className="space-y-6 text-lg text-gray-800">
            {data.addon.paragraphs.map((paragraph, index) => (
              <p key={index} className="leading-relaxed">
                {paragraph}
              </p>
            ))}
          </div>
        </section>
      </div>
    </article></> : <><NotFound/></>
  );
};

export default BlogRenderer;