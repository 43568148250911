import React from "react";
import { Helmet } from "react-helmet";
import BlogCard from "../newComponents/BlogCrad";
import bottomline from "../newComponents/asset/bottomline.png"

function Blog() {
  const blogs = [
    {
      image:
        "https://d2ehq5aws28ia0.cloudfront.net/blog/blog2.jpg",
      title: "Real Estate Investment Options and ROI in the Hill Stations",
      date: "Nov 11, 2024",
      href: "/blog/real-estate-investment-options",
      alt:"Real Estate Investment Options in the Hill Stations"
    },
    {
      image: "https://d57jfigkzby0a.cloudfront.net/ivm-blog.png",
      title: "Mukteshwar: Best Place to Invest in Real Estate",
      date: "Sep 25, 2024",
      href: "/blog/best-hill-station-for-real-estate-investment",
      alt:"Best Hill Station for Real Estate Investment"
    },
  ];

  return (
    <div>
      {/* Hero Section */}
      <div className="relative h-[130px] w-full">
        {/* <img
          className="h-full w-full object-cover object-center"
          src={`https://d2ehq5aws28ia0.cloudfront.net/blog/blogsBanner.jpg`}
          alt="Blog Cover"
        /> */}
      </div>

      <div className='flex flex-col mb-5 mt-[40px] justify-center items-center'>
        <p className='text-4xl font-bold text-center poppins text-green-700 uppercase tracking-wider yeseva'>
          Our Blogs 
        </p>
        <img className='ml-[-10px]' src={bottomline} alt=''/>
      </div>

      {/* Helmet for SEO */}
      <Helmet>
        <title>Explore Blogs About Mukteshwar & Uttarakhand Real Estate</title>
        <meta
          name="description"
          content="Get valuable insights on real estate in Mukteshwar and Uttarakhand with Indus Valley’s blog. Learn about property trends, luxury homes, and investment tips."
        />
      </Helmet>

      {/* Blog Grid Section */}
      <div className="p-6 md:px-10 lg:px-16 mb-16">
        <h2 className="text-2xl md:text-3xl font-semibold text-center mb-8">
          Explore Our Latest Articles
        </h2>
        <div className="flex justify-center">
  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
    {blogs.map((blog, index) => (
      <BlogCard key={index} blog={blog} />
    ))}
  </div>
</div>
      </div>
    </div>
  );
}

export default Blog;
