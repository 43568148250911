import React from 'react'
import bottomline from "./asset/bottomline.png"

function SiteLayout() {
  return (
    <div className='mx-auto mt-12'>
      {/* <div className='mx-auto text-center gap-5 w-[80%] flex justify-center items-center text-2xl uppercase yeseva'>
    <div className='w-full h-[2px] bg-gray-200'>
      </div>
      <p className="text-5xl w-full font-bold poppins text-green-700 uppercase tracking-wider yeseva">
      Site Layout
    </p>
    <div className='w-full h-[2px] bg-gray-200'>
      </div>
      </div> */}
      <div className='flex flex-col justify-center items-center'>
        <p className='text-3xl lg:text-4xl font-bold text-center poppins text-green-700 uppercase tracking-wider yeseva'>
          Site Layout
        </p>
        <img className='ml-[-10px]' src={bottomline} alt='bottomline'/>
      </div>
      <div className='m-5 h-full rounded-2xl '>
        <img className=" rounded-xl mx-auto" src="https://d2ehq5aws28ia0.cloudfront.net/ivmsitemap.png" alt="property in muktershwar site layout" />
        </div>
    </div>
  )
}

export default SiteLayout