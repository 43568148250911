import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import logo from "./asset/navlogo.png";

export default function Navbar() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isVisible, setIsVisible] = useState(true);
    const [prevScrollPos, setPrevScrollPos] = useState(0);
    
    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.scrollY;
            
            // Make navbar visible when scrolled to the very top
            if (currentScrollPos === 0) {
                setIsVisible(true);
                setPrevScrollPos(currentScrollPos);
                return;
            }
            
            // Determine scroll direction and update visibility
            const isScrollingDown = currentScrollPos > prevScrollPos;
            
            setIsVisible(!isScrollingDown);
            setPrevScrollPos(currentScrollPos);
        };

        window.addEventListener("scroll", handleScroll);
        
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [prevScrollPos]);

    const navLinks = [
        { path: "/", name: "Home" },
        { path: "/project-details/property-in-mukteshwar-for-sale", name: "About" },
        { path: "https://www.indusvalleyresort.com/", name: "Homestay", external: true },
        { path: "/ready_to_move", name: "Ready To Move" },
        { path: "/gallery", name: "Gallery" },
        { path: "/nearby", name: "Nearby" },
        { path: "/blogs", name: "Blogs" }
    ];

    const NavItem = ({ path, name, external }) => {
        if (external) {
            return (
                <li>
                    <a 
                        href={path}
                        onClick={() => setIsMenuOpen(false)}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="lg:text-gray-600 text-white text-lg hover:text-green-500 transition ease-in-out delay-150 duration-150 merriweather font-medium cursor-pointer uppercase"
                    >
                        {name}
                    </a>
                </li>
            );
        }

        return (
            <li>
                <NavLink
                    to={path}
                    onClick={() => setIsMenuOpen(false)}
                    className={({ isActive }) =>
                        `text-lg ${isActive ? 'text-green-600' : 'text-white lg:text-gray-600 hover:text-green-500'} transition ease-in-out delay-150 duration-150 merriweather font-medium cursor-pointer uppercase`
                    }
                >
                    {name}
                </NavLink>
            </li>
        );
    };

    return (
        <>
            <div 
                className={`w-[95%] rounded-xl z-40 h-[120px] card fixed mx-auto top-5 transition-transform duration-300 ${
                    isVisible ? 'translate-y-0' : '-translate-y-[120%]'
                }`}
            >
                <div className="container lg:w-full h-full mx-auto flex justify-between items-center">
                    {/* Logo */}
                    <div>
                        <NavLink to="/">
                            <img className="h-[100px]" src={logo} alt="main logo" />
                        </NavLink>
                    </div>

                    {/* Desktop Menu */}
                    <div className="hidden lg:block">
                        <ul className="flex gap-8">
                            {navLinks.map((link) => (
                                <NavItem
                                    key={link.path}
                                    path={link.path}
                                    name={link.name}
                                    external={link.external}
                                />
                            ))}
                        </ul>
                    </div>

                    {/* Mobile Menu Hamburger */}
                    <div className="lg:hidden">
                        <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="text-gray-600 hover:text-green-500">
                            <svg
                                className="w-8 h-8 mr-5"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M4 6h16M4 12h16M4 18h16"
                                />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>

            {/* Mobile Menu */}
            {isMenuOpen && (
                <div className="fixed h-[100vh] top-0 left-0 w-full bg-black/70 flex items-center justify-center z-50 transition-opacity duration-300">
                    <button
                        className="absolute top-6 right-6 p-2 text-white hover:bg-white hover:text-black rounded-full transition"
                        onClick={() => setIsMenuOpen(false)}
                    >
                        ✖
                    </button>
                    <ul className="flex flex-col items-center gap-8 text-white text-xl">
                        {navLinks.map((link) => (
                            <NavItem
                                key={link.path}
                                path={link.path}
                                name={link.name}
                                external={link.external}
                                onClick={() => setIsMenuOpen(false)}
                            />
                        ))}
                    </ul>
                </div>
            )}
        </>
    );
}