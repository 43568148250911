import React from "react";
import bottomline from "../newComponents/asset/bottomline.png"

function Testimonials() {
  return (
    <div
      className="w-full bg-cover lg:bg-opacity-80 py-5 "
    >
      <div className='flex flex-col mt-[60px] justify-center items-center'>
                <p className='text-3xl lg:text-4xl font-bold text-center poppins text-green-700 uppercase tracking-wider yeseva'>
                    Testimonials
                </p>
                <img className='ml-[-10px]' src={bottomline} alt='' />
            </div>
      <div className="flex gap-5 justify-center items-center container mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 m-auto">
          {[
            "lxsUgVAG5DU",
            "JJ4YaA4K2dg",
            "3VCF5F7AUx4",
            "ZPsYsz52Hms?start=2",
          ].map((videoId, index) => (
            <section
              key={index}
              className="text-center mx-auto p-6 lg:py-10 font-glacial transform transition-transform duration-500 hover:scale-110"
            >
              <iframe
                src={`https://www.youtube.com/embed/${videoId}`}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                height={200}
                title="YouTube video player"
                width={300}
              ></iframe>
            </section>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Testimonials;
