import React from 'react'
import bottomline from "../newComponents/asset/bottomline.png"

function Projectreview() {
  return (
    <div className='w-[90%] flex flex-col lg:flex-row gap-12 overflow-y-auto text-justify mx-auto h-auto pt-8 mb-12 px-12 mt-8'>
      {/* Left content section */}
      <div className='w-full'>
      <div className="flex flex-col justify-center items-center">
        <h1 className="text-3xl lg:text-4xl font-bold text-center poppins text-green-700 uppercase tracking-wider yeseva">
        Project Overview
        </h1>
        <img className="ml-[-10px]" src={bottomline} alt="" />
      </div>
        <p className='poppins text-lg tracking-wide text-justify font-base text-gray-600'>
          Welcome to Indus Valley Mukteshwar, a place that connects you to the majestic beauty of the Himalayas. Spanning across 10 acres of land, this private gated township in the lap of the Himalayas can be a perfect destination for you to invest in your Himalayan facing dream house. 
          Located at a distance of 326 Km (7-hour drive) from Delhi and a 1.5-hour drive from Nainital, this property is nestled at 7,358 ft above sea level. It offers breathtaking 360-degree views of the snow-covered Nanda Devi Himalayan range, surrounded by pine and deodar trees, valleys, and fruit orchards. 
          <br />
          "A RESIDENCE OF LORD SHIVA "
          This exclusive township is diversified to offer various accommodation options: studios and apartments, custom-built cottages, and premium semi-wooden villas. All the necessary government approvals, including the NOC from RERA, are in place.
          The amenities that come along with our gated community include electricity and water connections, dedicated parking spaces, a clubhouse, a restaurant, solar street lights, CCTV security, and much more. 
          These Himalayan-facing cottages serve three purposes: vacation homes, retirement homes, and opportunities for those who want to run their homestay and seek a return on investment.
          Discover peace; Discover the Indus Valley.
        </p>
      </div>
    </div>
  )
}

export default Projectreview;
