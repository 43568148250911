import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Home from "./screens/Home";
import Footer from "./newComponents/Footer";
import Navbar from "./newComponents/Navbar";
import About from "./screens/About";
import ScrollToTop from "./newComponents/Scrolltotop";
import Readytomovein from "./screens/Readytomovein";
import RenderComponent from "./newComponents/RenderComponent";
import Blog from "./screens/Blog";
import BlogRenderer from "./newComponents/BlogRender";
import Contact from "./screens/Contact";
import Nearby from "./screens/Nearby";
import Gallery3 from "./screens/Gallery3";
import NotFound from "./screens/NotFound";
import LandingPage from "./newComponents/LandingPage";
import MetaTags from "./screens/MetaTags";

function AppContent() {
  const location = useLocation();
  const showHeaderFooter = location.pathname !== "/landing";
  

  return (
    <div className="bg-green-50 bgimgcus overflow-hidden">
      <ScrollToTop />
      <div className="flex justify-center items-center">
        {showHeaderFooter && <Navbar />}
      </div>
      
      <div className="rounded-md flex flex-col fixed z-40 bottom-0 right-0 gap-5 m-5">
        <a
          href="https://www.google.com/maps/dir//Dhanachuli,State+Highway,+Nainital,+Uttarakhand+263132/@29.39809,79.5933096,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3908d7f7740c0bc9:0xe9baaef9dd824df2!2m2!1d79.675711!2d29.3981153?entry=ttu"
          target="_blank"
          rel="noreferrer"
        >
          <img 
            className="hover:scale-125 transition-all duration-100" 
            width="48" 
            height="48" 
            src="https://img.icons8.com/color/96/marker--v1.png" 
            alt="location"
          />
        </a>
        <a href="tel:+918510850101">
          <img 
            className="hover:scale-125 transition-all duration-100" 
            width="48" 
            height="48" 
            src="https://www.theshubhamgroup.com/static/media/icon_call.092a33d874f46145879d.png" 
            alt="phone"
          />
        </a>
        <a href="https://wa.me/918510850101" target="_blank" rel="noreferrer">
          <img 
            className="hover:scale-125 transition-all duration-100" 
            width="48" 
            height="48" 
            src="https://www.theshubhamgroup.com/static/media/icon_whatsapp.417ad2d4d35da4649b19.png" 
            alt="whatsapp--v1"
          />
        </a>
      </div>

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/project-details/property-in-mukteshwar-for-sale" element={<About />} />
        <Route path="/ready_to_move" element={<Readytomovein />} />
        <Route path="/:link" element={<RenderComponent />} />
        <Route path="/gallery" element={<Gallery3 />} />
        <Route path="/blogs" element={<Blog />} />
        <Route path="/blog/:title" element={<BlogRenderer />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/landing" element={<LandingPage />} />
        <Route path="/nearby" element={<Nearby />} />
        {/* <Route path="/404" element={<NotFound /> } /> */}
      </Routes>

      {showHeaderFooter && <Footer />}
    </div>
  );
}

export default function App() {
  return (
    <BrowserRouter>
      <AppContent />
    </BrowserRouter>
  );
}
