import React, { useEffect, useState } from 'react'
import bottomline from "../newComponents/asset/bottomline.png"
import Card from '../newComponents/Card';
import { Helmet } from "react-helmet";
import AOS from "aos";
import "aos/dist/aos.css";
// import CardSkeleton from '../newComponents/CardSkeleton';

function Readytomovein() {

    // const [loading, setLoading] = useState(true)

    // useEffect(()=>{
    //     setTimeout(()=>{
    //         setLoading(false)
    //     }, 1000)
    // },[])
    useEffect(() => {
        AOS.init({
          disable: "phone",
          duration: 600,
          easing: "ease-out-cubic",
          once:true,
        });
      }, []);


    const properties = {
        luxury :[
            [
        {
            link: "Studio_1bhk_Apart_layout",
            title: "Studio Apartment",
            plot: "Plot No - 69 : F1/F2",
            price: "35 Lakhs",
            img:"https://d2ehq5aws28ia0.cloudfront.net/redaycardimages/1change.jpg",
            key:"Studio_1bhk_Apart_layout"
        },

        {
            link: "Plot_09",
            title: "1 BHK APARTMENT",
            plot: "Plot No - 09",
            price: "55 Lakhs",
            img:"https://d2ehq5aws28ia0.cloudfront.net/redaycardimages/2bhkchange.JPG",
            key:"Plot_09"
        },

        {
            link: "Plot_01",
            title: "2 BHK Cottage",
            plot: "Plot No - 01 (Site B)",
            price: "1 Cr",
            img:"https://d2ehq5aws28ia0.cloudfront.net/redaycardimages/2bhkn.JPG",
            key:"Plot_01"
        }],
        [
            {
            link: "Plot_02",
            title: "2 BHK Cottage",
            plot: "Plot No - 02 (Site B)",
            price: "1 Cr",
            img:"https://d2ehq5aws28ia0.cloudfront.net/redaycardimages/2bhkbn.JPG",
            key:"Plot_01"
        },
        {
            link: "Plot_27",
            title: "3 BHK Cottage",
            plot: "Plot No - 27",
            price: "1.5 Cr",
            img:"https://d2ehq5aws28ia0.cloudfront.net/redaycardimages/3bhkn.JPG",
            key:"Plot_27"
        },
        {
            link: "Cottage_4bhk",
            title: "4 BHK Cottage",
            plot: "Plot No - 43",
            price: "2.10 Cr",
            img:"https://d2ehq5aws28ia0.cloudfront.net/redaycardimages/4bhkn.JPG",
            key:"Cottage_4bhk"
        }]
    ],
        underConstruction : [
            [
        {
            link: "Plot_26",
            img: "https://d2ehq5aws28ia0.cloudfront.net/ready+to+move+in/PLOT+26A+LIGHT+EXTERIOR.jpg",
            title: "1 BHK APARTMENTS",
            plot: "Plot No - 26",
            price: "50 - 55 Lakhs",
        },
        {
            link: "Plot_36",
            img: "https://d2ehq5aws28ia0.cloudfront.net/plots/plot36/2.jpg",
            title: "2 BHK APARTMENTS",
            plot: "Plot No - 36",
            price: "60 - 65 Lakhs",
        },
        {
            link: "1bhk_simplex_duplex",
            img: "https://d2ehq5aws28ia0.cloudfront.net/plots/1bhksimplex/1bhksimplexmain.jpg",
            title: "1 BHK Cottage",
            plot: "Simplex/Duplex",
            price: "70 Lakhs",
        }],
        [
        {
            link: "2bhk_duplex",
            img: "https://d2ehq5aws28ia0.cloudfront.net/redaycardimages/duplex.jpg",
            title: "2 BHK Cottage",
            plot: "Simplex/Duplex",
            price: "80 Lakhs",
        }],
    ],
    semiWoodenCottages : [
        {
            link: "Wooden_2bhk",
            img: "https://d2ehq5aws28ia0.cloudfront.net/plots/woodensimplex/cardmain.jpg",
            title: "2 BHK Simplex",
            plotNo : "Plot No - 65",
            status: "UNDER CONSTRUCTION",
            price: "",
        },
        {
            link: "Wooden_2bhkDuplex",
            img: "https://d2ehq5aws28ia0.cloudfront.net/plots/2bhkduplex/cardmain.jpg",
            title: "2 BHK Duplex",
            plotNo : "Plot No - 62",
            status: "UNDER CONSTRUCTION",
            price: "",
        },
        {
            link: "Wooden_3bhk",
            img: "https://d2ehq5aws28ia0.cloudfront.net/plots/3bhkwooden/LEFT.jpg",
            title: "3 BHK Wooden Cottage",
            plotNo : "Plot No - 64 (300 sq. yd.)",
            status: "UNDER CONSTRUCTION",
            price: "",
        },
        {
            link: "Wooden_3bhk250",
            img: "https://d2ehq5aws28ia0.cloudfront.net/plots/3bhkwooden250/3bhk+200sqyd+(2).jpg",
            title: "3 BHK Wooden Cottage",
            plotNo : "Plot No - 63 (250 sq. yd.)",
            status: "UNDER CONSTRUCTION",
            price: "",
        },
    ]
}

    return (
        <>
        <div className='h-[60vh] w-full'>
            <img className='h-full w-full object-center object-cover' src="https://d57jfigkzby0a.cloudfront.net/ov3.JPG" alt="" />

        </div>
            <div className='flex flex-col mt-[60px] justify-center items-center'>
                <p className='text-3xl lg:text-4xl font-bold text-center poppins text-green-700 uppercase tracking-wider yeseva'>
                    Ready To Move In
                </p>
                <img className='ml-[-10px]' src={bottomline} alt='' />
            </div>
            {/* Helmet for SEO */}
      <Helmet>
        <title>Ready To Move In Properties In Mukteshwar Uttarakhand</title>
        <meta
          name="description"
          content="Looking for property in Mukteshwar? Explore luxury properties for sale in Mukteshwar with Indus Valley. Perfect for those seeking peace in the Himalayas."
        />
      </Helmet>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5 p-5 mt-[20px] w-full place-items-center" data-aos-anchor-placement="top-center"
      data-aos="fade-up">
                    {/* <Card /> */}
                    {
                        properties.luxury[0].map((e, i)=>(
                            <Card key={i} prop={e}/>
                        ))
                    }
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5 p-5 mt-[20px] w-full place-items-center" data-aos-anchor-placement="top-center"
      data-aos="fade-up">
                    {/* <Card /> */}
                    {
                        properties.luxury[1].map((e, i)=>(
                            <Card key={i} prop={e}/>
                        ))
                    }
                    </div>
                    <div className='flex flex-col mt-[20px] justify-center items-center' data-aos-anchor-placement="top-center"
      data-aos="fade-up">
                        <p className='text-3xl lg:text-4xl font-bold text-center poppins text-green-700 uppercase tracking-wider yeseva'>
                        UNDER CONSTRUCTION
                        </p>
                        <img className='ml-[-10px]' src={bottomline} alt='' />
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5 p-5 mt-[20px] w-full place-items-center" data-aos-anchor-placement="top-center"
      data-aos="fade-up">
                    {
                        properties.underConstruction[0].map((e, i)=>(
                            <Card key={i} prop={e}/>
                        ))
                    }
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5 p-5 mt-[20px] w-full place-items-center" data-aos-anchor-placement="top-center"
      data-aos="fade-up">
                    {
                        properties.underConstruction[1].map((e, i)=>(
                            <Card key={i} prop={e}/>
                        ))
                    }
                    </div>
                    <div className='flex flex-col mt-[20px] justify-center items-center' data-aos-anchor-placement="top-center"
      data-aos="fade-up">
                        <p className='text-3xl lg:text-4xl font-bold text-center poppins text-green-700 uppercase tracking-wider yeseva'>
                        PREMIUM SEMI WOODEN COTTAGES
                        </p>
                        <img className='ml-[-10px]' src={bottomline} alt='' />
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5 p-5 mt-[20px] w-full place-items-center" data-aos-anchor-placement="top-center"
      data-aos="fade-up">
                    {
                        properties.semiWoodenCottages.map((e, i)=>(
                            <Card key={i} prop={e}/>
                        ))
                    }
            </div>
        </>

    )
}

export default Readytomovein