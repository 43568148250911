// import { useState } from 'react';
import React, { useState, useEffect } from 'react'
import { ChevronLeft, ChevronRight, Pause, Play} from 'lucide-react';


function Carousel({images, alt}) {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isPlaying, setIsPlaying] = useState(true);
  const [isHovered, setIsHovered] = useState(false);
  const [loading, setLoading] = useState(true)


    useEffect(() => {
        let interval;
        if (isPlaying && !isHovered) {
          interval = setInterval(() => {
            setCurrentSlide(prev => 
              prev === images.length - 1 ? 0 : prev + 1
            );
          }, 3000);
        }
        return () => clearInterval(interval);
      }, [isPlaying, isHovered, images.length,]);


      useEffect(()=>{
        setTimeout(()=>{
          setLoading(false)
        },1000)
      }, [])

  return (
    <>
    {
      loading ? 
      <>
      <div className="h-[300px] md:h-[400px] lg:h-[500px] w-[90%] md:w-[85%] lg:w-[80%] mx-auto">
  <div className="relative h-full overflow-hidden rounded-xl bg-gray-300 animate-pulse">
    {/* Image Skeleton */}
    <div className="aspect-[16/9] bg-gray-300" />

    {/* Left Arrow Skeleton */}
    <div className="absolute left-4 top-1/2 -translate-y-1/2 bg-gray-400 p-2 rounded-full h-8 w-8 md:h-10 md:w-10" />

    {/* Right Arrow Skeleton */}
    <div className="absolute right-4 top-1/2 -translate-y-1/2 bg-gray-400 p-2 rounded-full h-8 w-8 md:h-10 md:w-10" />

    {/* Play/Pause Button Skeleton */}
    <div className="absolute bottom-4 right-4 bg-gray-400 p-2 rounded-full h-8 w-8 md:h-10 md:w-10" />
  </div>
</div>

      </> :
    <div className='max-w-6xl'>
        <div
          className="relative overflow-hidden rounded-xl"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <div className="aspect-[16/9] relative">
            <img
              src={images[currentSlide]}
              alt={currentSlide === 0 ? alt : ""}
              className="w-full h-full object-cover"
            />
          </div>

          <button
            onClick={() => setCurrentSlide(prev => prev === 0 ? images.length - 1 : prev - 1)}
            className="absolute left-4 top-1/2 -translate-y-1/2 bg-black/50 p-2 rounded-full text-white hover:bg-black/70 transition-colors"
          >
            <ChevronLeft size={24} />
          </button>
          <button
            onClick={() => setCurrentSlide(prev => prev === images.length - 1 ? 0 : prev + 1)}
            className="absolute right-4 top-1/2 -translate-y-1/2 bg-black/50 p-2 rounded-full text-white hover:bg-black/70 transition-colors"
          >
            <ChevronRight size={24} />
          </button>

          <button
            onClick={() => setIsPlaying(!isPlaying)}
            className="absolute bottom-4 right-4 bg-black/50 p-2 rounded-full text-white hover:bg-black/70 transition-colors"
          >
            {isPlaying ? <Pause size={24} /> : <Play size={24} />}
          </button>

        </div>
    </div>
}
    </>
  )
}

export default Carousel