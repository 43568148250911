import { useState, useEffect } from "react";

export default function VideoBanner() {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(()=>{
    setTimeout(()=>{
      setIsLoading(false)
    },1000)
  })

  return (
    <div className="relative w-full h-auto mt-[150px] md:mt-0 aspect-video">
      {isLoading && (
        <div className="absolute inset-0 bg-gray-300 animate-pulse rounded-lg"></div>
      )}
      <video
        className={`w-full h-full ${isLoading ? "invisible" : "visible"}`}
        muted
        autoPlay
        loop
        playsInline
        controls
        onLoadedData={() => setIsLoading(false)}
      >
        <source
          src="https://d2ehq5aws28ia0.cloudfront.net/videos/banner_video.mp4"
          type="video/mp4"
        />
      </video>
    </div>
  );
}
