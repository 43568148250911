import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
    return (<>
    <meta name="robots" content="noindex"/>
        <div className="flex flex-col items-center justify-center mt-[150px] px-4">
            <div className="p-8 rounded-lg text-center max-w-md w-full">
                <h1 className="text-9xl poppins font-bold text-green-600 mb-4">404</h1>
                    <>
                        <h2 className="text-2xl poppins font-semibold text-gray-800 mb-3">
                            Page Not Found
                        </h2>
                        <p className="text-gray-600 poppins mb-4">
                            The page you are looking for might have been removed 
                            or is temporarily unavailable.
                        </p>
                    </>
                <div className="flex justify-center space-x-4">
                    <Link 
                        to="/" 
                        className="bg-green-600 text-white px-4 py-2 rounded hover:bg-green-700 transition"
                    >
                        Go to Homepage
                    </Link>
                        <button 
                            onClick={() => window.history.back()}
                            className="bg-gray-200 text-gray-800 px-4 py-2 rounded hover:bg-gray-300 transition"
                        >
                            Go Back
                        </button>
                </div>
            </div>
        </div>
        </>
    );
};

export default NotFound;